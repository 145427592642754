<template>
  <v-dialog
    persistent
    scrollable
    width="400"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text class="pt-4">
        <v-row class="mb-4">
          <v-col>
            <app-text-field v-model="form.name" label="Nome da sala" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="text-right">
        <app-delete-btn
          v-if="!isInsert"
          @click="handleDelete()"
          class="mr-auto"
        />
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="primary" @click="save()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,

    form: {},

    baseForm: {
      name: null,
    },
  }),

  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      return this.isInsert ? "Nova Sala" : "Editar Sala";
    },
  },

  methods: {
    open(data = null) {
      this.reset();

      if (data) {
        this.setFormData(data);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setFormData(data) {
      this.form = JSON.parse(JSON.stringify(data));
    },

    save() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    async store() {
      this.$loading.start();
      await this.$http
        .store("company/office", this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async update() {
      this.$loading.start();
      await this.$http
        .update("company/office", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.dialog = false;
      this.$emit("store");
      this.$loading.finish();
    },
    handleDelete() {
      this.$loading.start();
      this.$http
        .destroy("company/office", this.form.id)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
