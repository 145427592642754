<template>
  <div>
    <v-row>
      <v-col class="text-right" cols="12">
        <v-btn @click="insert()" bottom large color="primary"> Novo </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="offices"
      :items-per-page="-1"
      @click:row="edit($event)"
      hide-default-footer
      disable-sort
    ></v-data-table>
    <OfficeForm ref="OfficeForm" @store="refresh()" />
  </div>
</template>

<script>
import OfficeForm from "@/components/company/forms/OfficeForm.vue";

export default {
  components: { OfficeForm },

  data: () => ({
    headers: [
      { text: "Nome", align: "start", value: "name" },
      { text: "", align: "end", value: "action" },
    ],
  }),

  computed: {
    offices() {
      return this.$store.state.office.offices;
    },
  },

  methods: {
    insert() {
      this.$refs.OfficeForm.open();
    },

    edit(office) {
      //console.log
      this.$refs.OfficeForm.open(office);
    },

    refresh() {
      this.$store.dispatch("office/getOffices");
    },
  },
};
</script>

<style></style>
